import Vue from "vue";
import Vuex from "vuex";
import store from "./store";
import moment from "moment";
import "moment-timezone";
import WeekendHighlight from "./library/weekendhighlight";
import ChartTimelineItemsRowItem from "./library/label";
import ListColumnRowExpanderToggle from "./library/toggle";
import ListColumnRow from "./library/columnrow";
import user from "./store/auth/index";
import { BookingApiUrls } from "@/services/accommodationRequests/booking.api.js";
import toast from "./plugins/toast";

var arr = [];
var arr_elements = [];
let timeout = 0;
// открыть попап с квизом опреденной брони
function openBooking(element, data) {
  function onClick(event) {
    if (data.item.status == "koika") return;
    store.commit("OPEN_POPUP", data);
  }

  function onHover(event) {
    timeout = setTimeout(() => {
      // открыть tooltip только после 2000ms
      store.commit("OPEN_TOOLTIP", data);
    }, 2000);
  }
  if (!Vue.prototype.$mobileCheck) {
    element.addEventListener("click", onClick);
    element.addEventListener("mouseenter", onHover);
    element.addEventListener("mouseleave", () => {
      clearTimeout(timeout);
    });
  } else {
    element.addEventListener("touchend", onClick);
  }
  return {
    update(element, newData) {
      data = newData;
    }
  };
}

// клик на один пустой квадратик в шахматке
function clickSingleBlock(element, data) {
  var touchmoved;

  function onClick(event) {
    if (touchmoved) {
      return;
    }
    const dataSet = {
      room: "",
      inDate: "",
      outDate: ""
    };
    if (arr.length == 0 || arr.length == 1) {
      if (!data.row.parentId) {
        toast.error("Это категория!");
        return;
      }
      arr.push(data);
      arr_elements.push(element);
    }

    // добавить className для выделенных квадратиков
    arr_elements.forEach(e => {
      e.classList.add(
        "gantt-schedule-timeline-calendar__chart-timeline-grid-row-block--selected"
      );
    });

    // выделить квадратики между двумя датами для суточной брони
    let is_begin = false;
    let is_finished = false;
    if (
      arr_elements.length == 2 &&
      arr_elements[0].parentNode == arr_elements[1].parentNode &&
      arr_elements[0] != arr_elements[1]
    ) {
      if (arr[0].id > arr[1].id) {
        arr_elements.reverse();
      }

      // arr_elements[0].parentNode.children.forEach(e => {
      //   // добавление selected class в ячейки между двух выбранных
      //   if (e == arr_elements[0]) {
      //     is_begin = true;
      //   } else if (e == arr_elements[1]) {
      //     is_finished = true;
      //   } else if (is_begin && !is_finished) {
      //     e.classList.add(
      //       "gantt-schedule-timeline-calendar__chart-timeline-grid-row-block--selected"
      //     );
      //   }
      // });
      Array.from(arr_elements[0].parentNode.children).forEach(e => {
        // добавление selected class в ячейки между двух выбранных
        if (e == arr_elements[0]) {
          is_begin = true;
        } else if (e == arr_elements[1]) {
          is_finished = true;
        } else if (is_begin && !is_finished) {
          e.classList.add(
            "gantt-schedule-timeline-calendar__chart-timeline-grid-row-block--selected"
          );
        }
      });
    }

    if (arr.length == 2) {
      if (arr[0].row.id != arr[1].row.id) {
        // убрать selected class и очистить array, если разные номера
        toast.error("Выберите даты из одного номера");
        arr = [];
        setTimeout(() => {
          arr_elements.forEach(e => {
            e.classList.remove(
              "gantt-schedule-timeline-calendar__chart-timeline-grid-row-block--selected"
            );
          });
          arr_elements = [];
        }, 1000);
        return;
      }
      if (arr[0].id == arr[1].id) {
        // открыть попап с формой для часовой брони
        setTimeout(() => {
          store.commit("GET_ROOM_HOUR", data);
        }, 100);
        arr = [];
        setTimeout(() => {
          arr_elements[0].parentNode.children.forEach(e => {
            e.classList.remove(
              "gantt-schedule-timeline-calendar__chart-timeline-grid-row-block--selected"
            );
          });
          arr_elements = [];
        }, 1000);
      } else {
        // открыть попап с формой для суточной брони
        if (arr[0].time.rightGlobal > arr[1].time.rightGlobal) {
          dataSet.inDate = moment(arr[1].time.rightGlobal).format("YYYY-MM-DD");
          dataSet.outDate = moment(arr[0].time.rightGlobal).format(
            "YYYY-MM-DD"
          );
        } else {
          dataSet.inDate = moment(arr[0].time.rightGlobal).format("YYYY-MM-DD");
          dataSet.outDate = moment(arr[1].time.rightGlobal).format(
            "YYYY-MM-DD"
          );
        }
        if (arr[0].row.is_room)
          dataSet.room = +arr[0].row.id - myStore.state.count;
        // удалил state.count
        else dataSet.room = +arr[0].row.id;
        dataSet.category_id = +arr[0].row.parentId;
        dataSet.category_type = arr[0].row.category_type;
        setTimeout(() => {
          store.commit("SET_BOOKING_OPEN", dataSet);
        }, 200);
        arr = [];
        // setTimeout(() => {
        //   arr_elements[0].parentNode.children.forEach(e => {
        //     e.classList.remove(
        //       "gantt-schedule-timeline-calendar__chart-timeline-grid-row-block--selected"
        //     );
        //   });
        //   arr_elements = [];
        // }, 1000);
        setTimeout(() => {
          // Проверяем, что arr_elements определен и содержит элементы
          if (arr_elements && arr_elements.length > 0 && arr_elements[0].parentNode) {
            // Удаляем класс у всех дочерних элементов родителя
            Array.from(arr_elements[0].parentNode.children).forEach(e => {
              e.classList.remove(
                "gantt-schedule-timeline-calendar__chart-timeline-grid-row-block--selected"
              );
            });
          } else {
            console.error("arr_elements is undefined, empty, or does not have a parentNode");
          }

          // Очищаем массив arr_elements
          arr_elements = [];
        }, 1000);
      }
    }
  }
  if (Vue.prototype.$mobileCheck) {
    element.addEventListener("touchstart", function (e) {
      touchmoved = false;
    });
    element.addEventListener("touchmove", function (e) {
      touchmoved = true;
    });
    element.addEventListener("touchend", onClick);
  } else {
    element.addEventListener("click", onClick);
  }
}

function getInfo(text) {
  if (text !== null && text !== undefined) {
    return text;
  }
  return "";
}

// дать стиль для каждой брони
function getStyle(status, is_searched) {
  let my_style = {};
  if (status === "reserved") {
    my_style = {
      background: "orange",
      background_upd: "orange",
      color: "black",
      color_upd: "black",
      "font-weight": "bold",
      "border-radius": "10px"
    };
  } else if (status === "booked") {
    my_style = {
      background: "yellow",
      background_upd: "yellow",
      color: "#171717",
      color_upd: "#171717",
      "font-weight": "bold"
    };
  } else if (status === "check_in") {
    my_style = {
      background: "rgb(22, 221, 22)",
      background_upd: "rgb(22, 221, 22)",
      color: "black",
      color_upd: "black",
      "font-weight": "bold"
    };
  } else if (status === "block") {
    my_style = {
      "z-index": -1,
      color_upd: "black",
      background: "rgba(79, 0, 255, .5)",
      background_upd: "rgba(79, 0, 255, .5)"
    };
  } else if (status == "time") {
    my_style = {
      background: "rgba(255, 0, 0, 0.5)",
      background_upd: "rgba(255, 0, 0, 0.5)",
      "font-size": "14px",
      color_upd: "black"
    };
  } else if (status == "koika") {
    my_style = {
      background: "deeppink",
      background_upd: "deeppink",
      "font-size": "14px",
      color_upd: "black"
    };
  } else if (
    status === "canceled" ||
    status === "client_cancel" ||
    status === "company_cancel" ||
    status === "expired" ||
    status === "check_out" ||
    status === "repaired"
  ) {
    my_style = {
      background: "lightgray",
      background_upd: "lightgray",
      color: "black",
      color_upd: "black",
      "font-weight": "bold"
    };
  }
  if (is_searched) {
    my_style["background"] = "#5BC4FF";
    my_style["background_upd"] = "#5BC4FF";
    (my_style["color_upd"] = "black"), (my_style["transform"] = "scale(1.1)");
  }
  return my_style;
}
// изменить чистоту комнаты
window.changePolutionStatus = payload => {
  const selectedELem = document.getElementById("option-" + payload.id);
  const status = Number(selectedELem.value);
  const statusList = ["clean", "dirty", "repair"];
  window.axios
    .patch("/api/v2/categories/rooms/" + payload.id + "/", {
      status: statusList[status],
      category: payload.category
    })
    .then(() => {
      payload.status = statusList[status];
      payload.update = true;
      store.commit("ADD_ROOMS", payload);
    });
};

Vue.use(Vuex);

const endDay = new Date();
const beginDay = new Date();
const myStore = {
  state: {
    triggerCheckNotificationsLoad: 0, // триггер когда изменяются компании/уведомленния чтобы обновить header
    open_click: 0, // триггер когда происходят изменения datepickers в групповой брони в step1
    check_delete: false, // если новая бронь и не сохранена то удалить ее
    is_tooltip: false, // открыт ли tooltip
    tooltip_info: {}, // инфа tooltip
    bed_array: [], // список для коек
    updated_steady: 0, // проверка если steady изменился
    openHourRoom: false, // открытие модалки с формой часовой брони
    currentRoomHour: {}, // нужно для попапа с формой часовой брони
    isBlockOpen: false, // открыт ли попап блок
    blockSteady: {
      // инфа для попапа блок
      id: null,
      start: null,
      end: null
    },
    steady: {}, //  инфа по определенной брони
    openRepair: false, // открыт ли попап ремонт
    repairPayload: null, //  инфа для попапа ремонт

    searchString: "", // search сверху шахматки
    drawer: null, // сворачивает sidebar
    bookingModal: false, // открыт ли попап по созданию резерва
    reserve: false, // открыт ли попап с квизом опреденной брон
    config: {
      // config of gstc component
      resizer: {
        width: 1000,
        dots: 1
      },
      list: {
        rows: {},
        columns: {
          data: {
            label: {
              id: "label",
              data: "label",
              expander: true,
              isHTML: true,
              width: 250,
              minWidth: 100,
              header: {
                content: "Комнаты"
              }
            },
          }

        },
        toggle: {
          display: false
        }
      },
      chart: {
        spacing: 10,
        items: {
          0: {
            id: "1",
            rowId: "1",
            label: "",
            moveable: false,
            resizeable: false,
            style: {
              background: "lightgray",
              color: "black",
              "font-weight": "bold",
              display: "none",
              "font-size": "10px"
            },
            time: {
              start: new Date().getTime(),
              end: new Date("2021-01-17").getTime()
            }
          }
        },
        time: {
          calculatedZoomMode: true,
          from: beginDay.setHours(0, 0, 0),
          to: endDay.setDate(endDay.getDate() + 29), // 18400000, // +60 days 5097600000,
          leftGlobal: new Date().getDay()
        }
      },
      plugins: [
        WeekendHighlight({
          weekdays: [6, 0],
          className: "weekends-highlight"
        })
      ],
      actions: {
        "chart-timeline-items-row-item": [openBooking],
        "chart-timeline-grid-row-block": [clickSingleBlock]
      },
      locale: {
        name: "ru",
        weekdays: "ВС_ПН_ВТ_СР_ЧТ_ПТ_СБ".split("_"),
        weekdaysShort: "ВС_ПН_ВТ_СР_ЧТ_ПТ_СБ".split("_"),
        weekdaysMin: "ВС_ПН_ВТ_СР_ЧТ_ПТ_СБ".split("_"),
        months: "Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь".split(
          "_"
        ),
        monthsShort: "Янв_Фев_Мап_Апр_Май_Июн_Июл_Авг_Сен_Окт_Ноя_Дек".split(
          "_"
        ),
        weekStart: 1
      },
      components: {
        ChartTimelineItemsRowItem,
        ListColumnRowExpanderToggle,
        ListColumnRow
      }
    },
    el: 2, // step в брони с квизом
    status: "", // статус для удаления
    count: -10, // count будет равняться последней id из категории
    template: {}, // нужно для попапа с созданием резерва
    action: "empty", // если actions !== update, hide close button в попапе с квизом определенной брони, чтобы человек сначала сохранил данные и лишь потом close button появится
    polutionStatus: [
      // виды грязноты комнаты
      {
        text: "Чистый",
        value: "clean"
      },
      {
        text: "Грязный",
        value: "dirty"
      }
    ],
    possible_categories: [], // все категории одной компании с pageSize = 1000000
    payment_types: [], // виды оплат в step 4
    expanded_rows: [] // хендлить expanded rows для hover effect on booking
  },
  mutations: {
    setCheckinNull(state, payload) {
      state.steady.check_in = payload;
      state.steady.check_out = payload;
      console.log("cleared std", state.steady.check_out, state.steady.check_in)
    },
    // добавить или убрать из array элементы: категории и комнаты, которые могут сворачиваться
    // чтобы вручную их сворачивать
    SET_EXPANSED_ROWS(state, payload) {
      if (payload.type === "+") state.expanded_rows.push(+payload.id);
      else state.expanded_rows.splice(+payload.type, 1);
    },
    // виды оплат в step 4
    SET_PAYMENT_TYPES(state, payload) {
      state.payment_types = payload;
    },
    // триггер если компании или таски создались, чтобы в header сделать запрос
    SET_TRIGGER_CHECK_NOTIFICATIONS_LOAD(state, payload) {
      state.triggerCheckNotificationsLoad++;
    },
    // тригеррить сколько раз открывалась бронь
    SET_OPEN_CLICK(state, payload) {
      state.open_click += payload;
    },
    // если бронь сразу закроется, то удалить ее нужно
    SET_CHECK_DELETE(state, payload) {
      state.check_delete = payload;
    },
    // закрыть tooltip
    CLOSE_TOOLTIP(state, payload) {
      state.tooltip_info = {};
      state.is_tooltip = false;
    },
    // открыть tooltip
    OPEN_TOOLTIP(state, payload) {
      const id = +payload.item.id;
      for (const [key, value] of Object.entries(state.config.chart.items)) {
        if (+key == id + state.count) {
          Vue.set(state.config.chart.items[key].style, "background", "#9c27b0");
          Vue.set(state.config.chart.items[key].style, "color", "white");
          Vue.set(state.config.chart.items[key].style, "z-index", 10);
        } else {
          if (state.config.chart.items[key].style.background_upd) {
            Vue.set(
              state.config.chart.items[key].style,
              "background",
              state.config.chart.items[key].style.background_upd
            );
            Vue.set(
              state.config.chart.items[key].style,
              "color",
              state.config.chart.items[key].style.color_upd
            );
            Vue.set(state.config.chart.items[key].style, "z-index", 1);
          }
        }
      }
      for (const [key, value] of Object.entries(state.config.list.rows)) {
        if (state.expanded_rows.includes(+value.id))
          state.config.list.rows[key].expanded = true;
        else state.config.list.rows[key].expanded = false;
      }
      state.tooltip_info = payload;
      state.is_tooltip = true;
    },
    // для paddingLeft койки слева
    SET_BED_ARRAY(state, payload) {
      state.bed_array.push(payload);
      localStorage.setItem("bed_array", JSON.stringify(state.bed_array));
    },
    // для paddingLeft койки слева
    SET_EMPTY_BED_ARRAY(state, payload) {
      state.bed_array = [];
      localStorage.setItem("bed_array", JSON.stringify(state.bed_array));
    },
    // триггер когда изменится дата в попапе при создании резерва
    SET_BOOKING_FROM(state, payload) {
      Vue.set(
        state.template,
        "from",
        moment(payload.inDate).format("YYYY-MM-DD")
      );
      Vue.set(
        state.template,
        "to",
        moment(payload.outDate).format("YYYY-MM-DD")
      );
    },
    // проверить если хоть что-то из steasy изменилось, если 1 значит что-то изменилось
    SET_UPDATED_STEADY(state, payload) {
      state.updated_steady = payload;
    },
    // изменить длину колонны слева
    SET_WIDTH(state, payload) {
      state.config.list.columns.data.label.width = payload;
    },
    // закрыть часовую бронь
    CLOSE_HOUR_CALENDAR(state) {
      state.openHourRoom = false;
    },
    // открыть попап с формой по часовой брони
    GET_ROOM_HOUR(state, payload) {
      if (payload.row.is_bed) {
        toast.error("Вы не можете создать резерв на час в койке!");
        return;
      }
      if (!payload.row.canSelect) return;
      if (payload.selecting) return;
      if (payload.row.category_type == "only_day") {
        toast.error("Вы не можете создать резерв на час в этой категории!");
        return;
      }
      Object.assign(state.currentRoomHour, {
        ...payload,
        rowId: payload.row.id - state.count // удалил state.count
      });
      state.currentRoomHour.id = state.currentRoomHour.id.replace(
        `${payload.row.id}:`,
        ""
      );
      state.openHourRoom = true;
    },
    // изменить isBlockOpen: открыт ли попап блок
    OPEN_BLOCK(state, payload) {
      state.isBlockOpen = payload;
    },
    // вытянуть все категории
    SET_POSSIBLE_CATEGORIES(state, payload) {
      state.possible_categories = payload;
    },
    ADD_FOOD(state, payload) {
      if (!state.steady.food_list) {
        state.steady.food_list = [];
      }
      state.steady.food_list.push(payload);
    },
    // когда открываем бронь, эта функция работает, берет данные
    SET_STEADY(state, payload) {
      state.steady.payment_list = [];
      let stat = {
        ...state.steady
      };
      state.steady = {
        ...stat,
        ...payload
      };
      if (state.steady.group_parent > 0) {
        state.steady.is_group = true;
        state.steady.categories.forEach(i => {
          i.child_list.forEach(j => {
            let check_in = moment(Number(j.check_in) * 1000).format(
              "YYYY-MM-DD"
            );
            let check_out = moment(Number(j.check_out) * 1000).format(
              "YYYY-MM-DD"
            );
            let hour_check_in = moment(Number(j.check_in) * 1000).format(
              "HH:mm"
            );
            let hour_check_out = moment(Number(j.check_out) * 1000).format(
              "HH:mm"
            );
            j.hour_check_in = hour_check_in; // для локального time from picker
            j.hour_check_out = hour_check_out; // для локального time to picker
            j.date = [check_in, check_out]; // для локального date picker
            j.menu = false;
          });
        });
      } else {
        state.steady.is_group = false;
      }
      if (
        state.steady["clients_list"] &&
        state.steady["clients_list"].length == 0
      ) {
        state.steady["clients_list"] = [
          {
            comment: "",
            email: "",
            first_name: "",
            gender: null,
            last_name: "",
            middle_name: "",
            phone_number: "",
            full_name: ""
          }
        ];
      } else {
        state.steady["clients_list"].forEach(e => {
          e.full_name = `${e.first_name} ${e.last_name} ${e.middle_name}`;
        });
      }
      if (payload?.expire_on && payload.expire_on) {
        state.steady.expire_on_date = moment(
          Number(payload.expire_on) * 1000
        ).format("DD-MM-YYYY");
        state.steady.expire_on_time = moment(
          Number(payload.expire_on) * 1000
        ).format("HH:mm");
      }
      if (state.steady.discount_list) {
        state.steady.discount_list.forEach(e => {
          if (typeof e.discount === "object") {
            e.discount_upd = e.discount; // для локального хранения
            e.discount = e.discount.id;
          }
        });
      }
      let services = [];
      if (state.steady.calculated_by && state.steady.calculated_by.services) {
        state.steady.calculated_by.services.map(el => {
          let service = el;
          service.service = el.service.id;
          services.push(service);
        });
      }
      state.steady.services = services;
      if (state.steady.task_list) {
        state.steady.task_list.forEach(element => {
          element.todo_date = moment(element.due_date * 1000).format(
            "YYYY-MM-DD"
          );
          element.todo_time = moment(element.due_date * 1000).format("HH:mm");
        });
      }
      state.steady.payment_list = state.steady.payment_list || [];
      state.steady.food_list = state.steady.food_list || [];
      this.commit("SET_OPEN_CLICK", 1);
    },
    // изменить openRepair: открыт ли попап ремонт
    SET_OPENREPAIR(state, payload) {
      state.openRepair = payload;
    },
    // почистить шахматку
    CLEAR_ROWS(state) {
      state.config.list.rows = {};
    },
    // почистить шахматку
    CLEAR_CALENDAR(state) {
      let item = state.config.chart.items["0"];
      state.config.chart.items = {
        "0": item
      };
    },
    // поиск по searchString сверху шахматки
    SEARCH_BAR(state, payload) {
      state.searchString = payload;
    },
    // шахматке дать height
    SET_HEIGHT(state, payload) {
      state.config.height = payload;
    },
    // скрыть left sidebar
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    // открыть попап с формой по созданию суточной брони
    SET_BOOKING_OPEN(state, payload) {
      if (payload.category_type == "only_time") {
        toast.error("Вы не можете создать резерв на сутки в этой категории!");
        return;
      }
      state.template.room = payload.room;
      Vue.set(
        state.template,
        "from",
        moment(payload.inDate).format("YYYY-MM-DD")
      );
      Vue.set(
        state.template,
        "to",
        moment(payload.outDate).format("YYYY-MM-DD")
      );
      state.bookingModal = !state.bookingModal;
      console.log("testttttttt")
    },
    // закрыть на ремонт
    CLOSE_REPAIR(state) {
      state.reserve = false;
      state.bookingModal = false;
    },
    // если actions !== update, hide close button в попапе с квизом определенной брони, чтобы человек сначала сохранил данные и лишь потом close button появится
    SET_ACTION(state, payload) {
      state.action = payload;
    },
    // изменить статус для удаления
    SET_STATUS(state, payload) {
      state.status = payload;
    },
    // открывает форму бронирования
    OPEN_CLOSE_RESERVE(state, payload) {
      console.log(payload, state.template, state.template.room);
      state.template.status = payload.status;
      state.action = null;
      if (payload.status === "repair") {
        var obj = {};
        var id = Object.keys(state.config.chart.items).length + 1;
        obj.id = "" + id;
        obj.rowId = state.template.room + state.count; // удалил state.count
        obj.label = "Закрыто на ремонт";
        obj.moveable = false;
        (obj.repair_id = payload.repair_id), (obj.resizeable = false);
        obj.status = payload.status;
        obj.style = {};
        obj.style.background = "gray";
        obj.time = {};
        obj.time.start = new Date(state.template.from).setHours(0, 0, 0, 0);
        obj.time.end =
          new Date(state.template.to).setHours(24, 60, 60, 60) +
          6 * 59 * 59 * 1000;
        Vue.set(state.config.chart.items, id, obj);

        state.status = payload.status;
        return;
      }
      if (payload.status === "new") {
        state.bookingModal = false;
      } else {
        state.bookingModal = !state.bookingModal;
      }
      // Действие после зарезервирования блока, закрывается блок инпута имени, открывается бронь
      // console.log("ZAREGATSYA")
      state.reserve = !state.reserve;
      state.status = payload.status;
      if (state.status === "reserve") {
        state.el = 1;
      }
    },
    // назад по дням, триггер: кнопка со стрелкой влево сверху шахматки
    PREV_DAY(state) {
      const begDay = new Date(state.config.chart.time.from);
      const endDay = new Date(state.config.chart.time.to);
      state.config.chart.time.from = begDay.setDate(begDay.getDate() - 1);
      state.config.chart.time.to = endDay.setDate(endDay.getDate() - 1);
    },
    // вперед по дням, триггер: кнопка со стрелкой вправо сверху шахматки
    NEXT_DAY(state) {
      const begDay = new Date(state.config.chart.time.from);
      const endDay = new Date(state.config.chart.time.to);
      state.config.chart.time.from = begDay.setDate(begDay.getDate() + 1);
      state.config.chart.time.to = endDay.setDate(endDay.getDate() + 1);
    },
    // изменить период по шахматке
    CHANGE_PERIOD(state, payload) {
      // показать ли цену при разном периоде на шахматке
      setTimeout(() => {
        if (payload.period == 7) {
          let spans = document.querySelectorAll(
            ".gantt-schedule-timeline-calendar__chart-timeline-items-row-item-label_span"
          );
          for (let i = 0; i < spans.length; i++) {
            spans[i].style.display = "block";
          }
        } else if (payload.period == 15) {
          let spans = document.querySelectorAll(
            ".gantt-schedule-timeline-calendar__chart-timeline-items-row-item-label_span"
          );
          let inputs = document.querySelectorAll(
            ".gantt-schedule-timeline-calendar__chart-timeline-items-row-item-label_input"
          );
          for (let i = 0; i < inputs.length; i++) {
            if (Number.parseInt(inputs[i].value) < 86400000) {
              // 24 часов = 86400000
              spans[i].style.display = "none";
            }
          }
        } else {
          let spans = document.querySelectorAll(
            ".gantt-schedule-timeline-calendar__chart-timeline-items-row-item-label_span"
          );
          for (let i = 0; i < spans.length; i++) {
            if (spans[i].style.id == "none") {
              spans[i].style.display = "none";
            }
          }
        }
      }, 3000);

      // hardcode для длины шахматки
      if (payload.period == 15) {
        if (Vue.prototype.$mobileCheck) {
          document.querySelector(
            ".gantt-schedule-timeline-calendar"
          ).style.width = "150vw";
        }
      } else if (payload.period == 30) {
        if (Vue.prototype.$mobileCheck) {
          document.querySelector(
            ".gantt-schedule-timeline-calendar"
          ).style.width = "300vw";
        }
      } else if (payload.period == 60) {
        if (Vue.prototype.$mobileCheck) {
          document.querySelector(
            ".gantt-schedule-timeline-calendar"
          ).style.width = "600vw";
        } else {
          document.querySelector(
            ".gantt-schedule-timeline-calendar"
          ).style.width = "180vw";
        }
      }

      const day = new Date(payload.today);
      state.config.chart.time.leftGlobal = state.config.chart.time.from = day.setDate(
        day.getDate()
      );
      state.config.chart.time.to = day.setDate(
        day.getDate() + payload.period + 4
      );
    },
    // добавить actions шахматке если их нет
    ADD_ACTIONS(state) {
      state.config.actions = {
        "chart-timeline-items-row-item": [openBooking],
        "chart-timeline-grid-row-block": [clickSingleBlock]
      };
    },
    // если изменили номер в брони, работает эта функция
    SET_ROOM(state, payload) {
      state.steady.room = payload;
    },
    // меняем тип питания
    SET_FOOD_TYPE(state, { id, details }) {
      const item = state.steady.food_list.find((i) => i.id === id);
      item.tariff = details;
      item.id = details.id;
    },
    // Удаление питания
    DELETE_FOOD_LIST_ITEM(state, { id }) {
      const index = state.steady.food_list.findIndex((i) => i.id === id)
      state.steady.food_list.splice(index, 1);
    },
    // открыть попап с квизом для определенной брони
    async OPEN_POPUP(state, payload) {
      state.steady.payment_list = [];
      if (payload.item.status === "time") {
        // открыть часовую бронь
        Object.assign(state.currentRoomHour, {
          ...payload,
          rowId: payload.item.rowId - state.count // удалил state.count
        });
        state.currentRoomHour.id = moment(payload.item.time.start).format(
          "YYYY-MM-DD HH:mm"
        );
        setTimeout(() => {
          state.openHourRoom = true;
        }, 500);
        return;
      }
      if (state.openHourRoom) {
        // если открытка часовая бронь, закрыть ее
        state.openHourRoom = false;
      }
      if (payload.item.status == "block") {
        if (user.getters.getIsAdmin || user.getters.getIsSuperuser) {
          const { data } = await window.axios.get(
            "/api/chanel/block/" + payload.item.block_id + "/"
          );
          state.blockSteady = data;
          state.blockSteady.start = moment(
            Number(state.blockSteady.start) * 1000
          ).format("YYYY-MM-DD");
          state.blockSteady.end = moment(
            Number(state.blockSteady.end) * 1000
          ).format("YYYY-MM-DD");
          state.isBlockOpen = !state.isBlockOpen;
        }
        return;
      }
      if (payload.item.label == "Закрыто на ремонт") {
        state.openRepair = true;
        state.repairPayload = payload;
        return;
      }
      localStorage.setItem("book_id", payload.item.id);

      const { data } = await BookingApiUrls.getBooking(payload.item.id);
      // происходит тоже самое что и в SET_STEADY mutation
      state.steady = {
        ...data
      };
      if (state.steady.group_parent > 0) {
        state.steady.is_group = true;
        state.steady.categories.forEach(i => {
          i.child_list.forEach(j => {
            let check_in = moment(Number(j.check_in) * 1000).format(
              "YYYY-MM-DD"
            );
            let check_out = moment(Number(j.check_out) * 1000).format(
              "YYYY-MM-DD"
            );
            let hour_check_in = moment(Number(j.check_in) * 1000).format(
              "HH:mm"
            );
            let hour_check_out = moment(Number(j.check_out) * 1000).format(
              "HH:mm"
            );
            j.is_early = false;
            j.is_late = false;
            j.hour_check_in = hour_check_in;
            j.hour_check_out = hour_check_out;
            j.date = [check_in, check_out];
            j.menu = false;
          });
        });
      } else {
        state.steady.is_group = false;
      }
      if (state.steady.task_list) {
        state.steady.task_list.forEach(element => {
          element.todo_date = moment(element.due_date * 1000).format(
            "YYYY-MM-DD"
          );
          element.todo_time = moment(element.due_date * 1000).format("HH:mm");
        });
      }
      state.steady.payment_list = state.steady.payment_list || [];
      state.steady.food_list = state.steady.food_list || [];
      if (state.steady.discount_list) {
        state.steady.discount_list.forEach(e => {
          if (typeof e.discount === "object") {
            e.discount_upd = e.discount;
            e.discount = e.discount.id;
          }
        });
      }

      if (state.steady["clients_list"].length == 0) {
        state.steady["clients_list"] = [
          {
            comment: "",
            email: "",
            first_name: "",
            gender: null,
            last_name: "",
            middle_name: "",
            phone_number: "",
            full_name: ""
          }
        ];
      } else {
        state.steady["clients_list"].forEach(e => {
          e.full_name = `${e.first_name} ${e.last_name} ${e.middle_name}`;
        });
      }
      state.steady["expire_on_date"] = "";
      state.steady["expire_on_time"] = "";
      if (data.has_expire && data.expire_on) {
        state.steady["expire_on_date"] = moment(
          Number(data.expire_on) * 1000
        ).format("DD-MM-YYYY");
        state.steady["expire_on_time"] = moment(
          Number(data.expire_on) * 1000
        ).format("HH:mm");
      }
      let services = [];
      if (state.steady.services) {
        state.steady.services.map(el => {
          let service = el;
          service.service = el.service.id;
          services.push(service);
        });
        state.steady.services = services;
      }
      // Открытие заррезервированного блока 
      this.commit("SET_OPEN_CLICK", 1);
      state.action = "update";
      state.el = 1;
      state.reserve = !state.reserve;

    },
    // изменяется el, то есть step в попапе с квизом
    TO_TAB(state, payload) {
      state.el = payload;
    },
    // добавить категорию или комнату в шахматку state.config
    ADD_CATEGORIES(state, payload) {
      let style = {};
      // если это категория
      if (payload.category) {
        style = {
          current: {
            "font-weight": "bold"
          },
          grid: {
            block: {
              current: {
                "font-weight": "bold"
              }
            }
          }
        };
      } else {
        // если это комната с койками
        style = {
          current: {
            background: "lightgray",
            "font-weight": "bold"
          },
          grid: {
            block: {
              current: {
                background: "lightgray",
                "font-weight": "bold"
              }
            }
          }
        };
      }
      let id;
      if (payload.category) id = Number(payload.id + state.count).toString();
      else {
        id = payload.id.toString();
        this.commit("SET_EXPANSED_ROWS", {
          id: id,
          type: "+"
        });
      }
      let label;
      let info = getInfo(payload.info);
      if (payload.category)
        label = `<div style="display: flex; justify-content: space-between; flex-wrap: wrap;">
        <div style="display: block"><div style="line-height: 19px !important;">${payload.name
          }</div><div style="line-height: 19px !important;">${info}</div></div>
        <div style="pointer-events: all !important; touch-action: auto !important; user-select: all !important; display: flex; margin-right: 5px; height: 20px;">
          <select class="custom-select" id="option-${payload.id}"
          onchange="changePolutionStatus( ${JSON.stringify(payload)
            .split('"')
            .join("&quot;")} )"
          style="padding-top: 0; margin-top: 0; height: 30px; top: -10px; color: ${payload.status === "clean"
            ? "rgb(22, 221, 22)"
            : payload.status === "dirty"
              ? "#ff0000"
              : "lightgray"
          }">
            <option style="color: rgb(22, 221, 22)" value="0" ${payload.status === "clean" ? "selected" : ""
          }>Чистый</option>
            <option style="color: #ff0000" value="1" ${payload.status === "dirty" ? "selected" : ""
          }>Грязный</option>
            <option disabled style="color: lightgray" value="2" ${payload.status === "repair" ? "selected" : ""
          }>Ремонт</option>
          </select>
        </div>
      </div>`;
      else label = payload.name;
      Vue.set(state.config.list.rows, id, {
        category_type: payload.category_type,
        id: id,
        label: label,
        is_room: payload.category ? true : false,
        canSelect: true,
        expanded: payload.category ? false : true,
        parentId: payload.category || null,
        style: style
      });
    },
    // добавить комнату или койку в шахматку state.config
    ADD_ROOMS(state, payload) {
      if (!payload.update)
        payload.booking_list.map(el => this.commit("UPDATE_ORDER", el));
      let info = getInfo(payload.info);
      let label = "";
      if (payload.is_bed)
        this.commit("SET_BED_ARRAY", payload.id + state.count);
      let id;
      if (payload.is_bed)
        id = Number(payload.category + state.count).toString();
      else id = payload.category.toString();
      if (Vue.prototype.$mobileCheck) {
        label = `<div style="display: flex; justify-content: space-between; flex-wrap: wrap;">
        <div style="display: block;"><div style="line-height: 19px !important; font-weight: bold !important;">${payload.name}</div><div style="line-height: 19px !important;">${info}</div></div>
      </div>`;
      } else {
        label = `<div style="display: flex; justify-content: space-between; flex-wrap: wrap;">
        <div style="display: block"><div style="line-height: 19px !important; font-weight: bold !important;">${payload.name
          }</div><div style="line-height: 19px !important;">${info}</div></div>
        <div style="pointer-events: all !important; touch-action: auto !important; user-select: all !important; display: flex; margin-right: 5px; height: 20px;">
          <select class="custom-select" id="option-${payload.id}"
          onchange="changePolutionStatus( ${JSON.stringify(payload)
            .split('"')
            .join("&quot;")} )"
          style="padding-top: 0; margin-top: 0; height: 30px; top: -10px; color: ${payload.status === "clean"
            ? "rgb(22, 221, 22)"
            : payload.status === "dirty"
              ? "#ff0000"
              : "lightgray"
          }">
            <option style="color: rgb(22, 221, 22)" value="0" ${payload.status === "clean" ? "selected" : ""
          }>Чистый</option>
            <option style="color: #ff0000" value="1" ${payload.status === "dirty" ? "selected" : ""
          }>Грязный</option>
            <option disabled style="color: lightgray" value="2" ${payload.status === "repair" ? "selected" : ""
          }>Ремонт</option>
          </select>
        </div>
      </div>`;
      }
      Vue.set(
        state.config.list.rows,
        Number(payload.id + state.count).toString(),
        {
          // я тут удалил Number(payload.id + state.count)
          category_type: payload.category_type,
          id: Number(payload.id + state.count).toString(), // я тут удалил Number(payload.id + state.count)
          label: label,
          canSelect: payload.status !== 2,
          is_room: true,
          expanded: false,
          parentId: id,
          is_bed: payload.is_bed
        }
      );
    },
    // count будет равняться последней id из категории
    CHANGE_CATEGORY_LENGTH(state, payload) {
      state.count = payload;
    },
    // добавить брони в шахматку gstc в config
    UPDATE_ORDER(state, payload) {
      let label = "";
      if (payload.client_name.length > 0) {
        label = payload.client_name;
      } else {
        label = "undefined";
      }
      let additional_time = 0;
      let object_id = payload.room;
      if (payload.booking_status === "repair") {
        object_id = payload.repair_id;
        additional_time = 6 * 60 * 60 * 1000;
      }
      if (
        payload.booking_status === "time" ||
        payload.booking_status === "koika"
      ) {
        object_id = payload.repair_id;
        additional_time = 6 * 60 * 1000;
      }
      Vue.set(
        state.config.chart.items,
        Number(payload.id + state.count).toString(),
        {
          // удалил state.count
          block_id: payload.block_id,
          id: payload.id.toString(),
          rowId: Number(payload.room + state.count).toString(), // удалил state.count
          label: label,
          moveable: false,
          resizeable: false,
          expander: false,
          style: getStyle(payload.booking_status, payload.is_found),
          time: {
            start: Number(payload.check_in) * 1000,
            end: Number(payload.check_out) * 1000 + additional_time
          },
          category_id: payload.category_id,
          object_id: object_id,
          source: payload.source_of_sales,
          client: payload.client_name,
          price: payload.price,
          common_amount: payload.duty,
          paid_amount: payload.paid_amount,
          status: payload.booking_status,
          free_time: payload.free_time
        }
      );
    }
  },
  getters: {
    getTooltipInfo(state) {
      return state.tooltip_info;
    },
    getopenHourRoom(state) {
      return state.openHourRoom;
    },
    getPossibleCategories: state => () => state.possible_categories,
    getPaymentTypes: state => () => state.payment_types,
    getEl: state => () => state.el,
    getrepairPayload: state => () => state.repairPayload,
    getSteady: state => () => state.steady,
    getblockSteady: state => () => state.blockSteady,
    getCurrentRoomHour: state => () => state.currentRoomHour,
    getFoodList: (state) => state.steady.food_list
  }
};
export default new Vuex.Store(myStore);
