import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/auth",
      component: () => import("@/views/auth/Index"),
      name: "auth",
      children: [
        {
          path: "login",
          component: () => import("@/views/auth/Login"),
          name: "auth.login"
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/views/main/Index"),
      children: [
        // main
        {
          name: "Календарь",
          path: "",
          component: () => import("@/views/main/CustomCalendar")
        },
        // Pages
        {
          name: "Состояние броней",
          path: "orders",
          component: () => import("@/views/main/pages/Orders")
        },
        {
          name: "Отчеты",
          path: "regular-tables",
          component: () => import("@/views/main/tables/Details")
        },
        {
          name: "Отчеты ПНЛ",
          path: "reports",
          component: () => import("@/views/main/pages/Reports")
        },
        {
          name: "Отчеты ПНЛ",
          path: "reports/:id",
          component: () => import("@/views/main/pages/ReportsId")
        },
        {
          name: "Каналы продаж",
          path: "list",
          component: () => import("@/views/main/pages/SalesChannels")
        },
        {
          name: "Источник продаж",
          path: "source",
          component: () => import("@/views/main/pages/SourceSales")
        },
        {
          name: "Список броней",
          path: "listBookings",
          component: () => import("@/views/main/pages/ListBookings")
        },
        {
          name: "Настройки",
          path: "settings",
          component: () => import("@/views/main/pages/SalesSettings")
        },
        {
          name: "Создание статей",
          path: "states",
          component: () => import("@/views/main/pages/States")
        },
        {
          name: "Расходы",
          path: "expenditure",
          component: () => import("@/views/main/pages/Expenditure")
        },
        {
          name: "Расходы",
          path: "expenditure/:date",
          component: () => import("@/views/main/pages/ExpenditureDate")
        },
        {
          name: "Создать источника",
          path: "create",
          component: () => import("@/views/main/pages/CreateSource")
        },
        {
          name: "Категория",
          path: "categories",
          component: () => import("@/views/main/pages/Categories")
        },
        {
          name: "Питание",
          path: "food-tariffs",
          component: () => import("@/views/main/pages/FoodTariffs")
        },
        {
          name: "Профиль",
          path: "profile",
          component: () => import("@/views/main/pages/UserProfile")
        },
        {
          name: "Информация об объекте",
          path: "object-info",
          component: () => import("@/views/main/pages/ObjectInformation")
        },
        {
          name: "Задачи лист",
          path: "tasks",
          component: () => import("@/views/main/pages/Tasks")
        },
        {
          name: "Задача",
          path: "task/:id",
          component: () => import("@/views/main/pages/Task")
        },
        {
          name: "Задачи",
          path: "task",
          component: () => import("@/views/main/pages/Task")
        },
        {
          name: "Скидки и купоны",
          path: "discount_list",
          component: () => import("@/views/main/pages/Discount")
        },
        {
          name: "Купон и скидка",
          path: "discount/:id",
          component: () => import("@/views/main/pages/SingleDiscount")
        },
        {
          name: "Скидка и купоны",
          path: "discount",
          component: () => import("@/views/main/pages/SingleDiscount")
        },
        {
          name: "Виды оплат",
          path: "payment_types",
          component: () => import("@/views/main/pages/PaymentTypes")
        },
        {
          name: "Местоположение",
          path: "location",
          component: () => import("@/views/main/pages/Location")
        },
        {
          name: "Номерной фонд",
          path: "rooms",
          component: () => import("@/views/main/pages/Rooms")
        },
        {
          name: "Тарифы",
          path: "tariffs",
          component: () => import("@/views/main/pages/Tariff")
        },
        {
          name: "Журнал действий сотрудников",
          path: "journal",
          component: () => import("@/views/main/pages/Journal")
        },
        {
          path: "additional-tariffs/add",
          name: "Добавление доп тарифа",
          component: () => import("@/views/main/pages/AdditionalTariff")
        },
        {
          path: "additional-tariffs/:id",
          name: "Доп тариф",
          component: () => import("@/views/main/pages/AdditionalTariff")
        },
        {
          path: "additional-tariffs",
          name: "Доп тарифы",
          component: () => import("@/views/main/pages/AdditionalTariffs")
        },
        // {
        //   path: 'orders/:id',
        //   component: () => import('@/views/main/pages/Booking')
        // },
        {
          path: "company/:id",
          component: () => import("@/views/main/pages/Company")
        },
        {
          path: "company",
          component: () => import("@/views/main/pages/Company")
        },
        {
          path: "category",
          component: () => import("@/views/main/pages/Category")
        },
        {
          path: "category/:id",
          component: () => import("@/views/main/pages/Category")
        },
        {
          path: "room",
          component: () => import("@/views/main/pages/Room")
        },
        {
          path: "room/:id",
          component: () => import("@/views/main/pages/Room")
        },
        {
          path: "service",
          component: () => import("@/views/main/pages/Service")
        },
        {
          path: "service/:id",
          component: () => import("@/views/main/pages/Service")
        },
        {
          path: "food",
          name: "Меню",
          component: () => import("@/views/main/pages/Food")
        },
        {
          path: "tariffsLeisure",
          name: "Тарифы активного отдыха",
          component: () => import("@/views/main/pages/tariffsLeisure")
        },
        {
          path: "employees",
          name: "Сотрудники",
          component: () => import("@/views/main/pages/Employees")
        },
        {
          path: "booking-module",
          name: "Модуль бронирования",
          component: () => import("@/views/main/pages/BookingModule")
        },
        {
          path: "leisureType",
          name: "Тип активного отдыха",
          component: () => import("@/views/main/pages/leisureType")
        },
        {
          path: "employee/:id",
          component: () => import("@/views/main/pages/Employee")
        },
        {
          path: "employee",
          component: () => import("@/views/main/pages/Employee")
        },
        {
          path: "add-blocks",
          component: () => import("@/views/main/pages/AddBlocks")
        },
        {
          name: "Компания",
          path: "companies",
          component: () => import("@/views/main/pages/Companies")
        },
        {
          name: "Популярность",
          path: "popular",
          component: () => import("@/views/main/pages/Popular")
        },
        {
          name: "Услуги",
          path: "services",
          component: () => import("@/views/main/pages/Services")
        },
        {
          name: "Создать задачу",
          path: "taskscreate",
          component: () => import("@/views/main/pages/taskcreate")
        },
        {
          path: "/qr",
          name: "QR",
          component: () => import("@/views/main/pages/qr/index.vue")
        }
      ]
    },
    {
      path: "/empty",
      name: "Пустая страница",
      component: () => import("@/views/main/Index")
    }
  ]
});

router.beforeResolve((to, from, next) => {
  if (from.name === "Модуль бронирования") {
    // в моем виджете стоит vuetify, который перебивает цвета на этой странице
    // поэтому я делаю reload страницы
    next();
    location.reload();
  } else {
    next();
  }
});

export default router;
