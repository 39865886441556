import Vue from "vue";
import Vuex from "vuex";
import store from "./index";

import { FOOD_TICKET_MANAGER } from "@/shared/config";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("token"),
    refresh_token: localStorage.getItem("refresh_token"),
    is_superuser: localStorage.getItem("is_superuser"),
    is_admin: localStorage.getItem("is_admin"),
    company_id: localStorage.getItem("company_id"),
    has_widget: localStorage.getItem("has_widget"),
    role: localStorage.getItem("role"),
    check_in_time: localStorage.getItem("check_in_time"),
    check_out_time: localStorage.getItem("check_out_time"),
    object_type: localStorage.getItem("object_type"),
    booking_time: localStorage.getItem("booking_time")
  },
  mutations: {
    setRole(state, perm) {
      localStorage.setItem("role", perm);
      state.role = perm;
    },
    setToken(state, payload) {
      localStorage.setItem("token", payload);
      state.token = localStorage.getItem("token");
    },
    setCheckInTime(state, payload) {
      localStorage.setItem("check_in_time", payload);
      state.check_in_time = localStorage.getItem("check_in_time");
    },
    setCheckOutTime(state, payload) {
      localStorage.setItem("check_out_time", payload);
      state.check_out_time = localStorage.getItem("check_out_time");
    },
    setRefreshToken(state, payload) {
      localStorage.setItem("refresh_token", payload);
      state.refresh_token = localStorage.getItem("token");
    },
    setIsSuperuser(state, payload) {
      localStorage.setItem("is_superuser", payload);
      state.is_superuser = localStorage.getItem("is_superuser");
    },
    setIsAdmin(state, payload) {
      localStorage.setItem("is_admin", payload);
      state.is_admin = localStorage.getItem("is_admin");
    },
    setHasWidget(state, payload) {
      localStorage.setItem("has_widget", payload);
      state.has_widget = localStorage.getItem("has_widget");
    },
    delRefreshToken(state) {
      localStorage.removeItem("refresh_token");
      state.refresh_token = localStorage.getItem("refresh_token");
    },
    delToken(state) {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      state.token = localStorage.getItem("token");
    },
    setCompany(state, payload) {
      localStorage.setItem("company_id", payload);
      state.company_id = localStorage.getItem("company_id");
    },
    setCompanyType(state, payload) {
      localStorage.setItem("object_type", payload);
      state.object_type = localStorage.getItem("object_type");
    },
    setIsBookingTime(state, payload) {
      localStorage.setItem("booking_time", payload);
      state.booking_time = localStorage.getItem("booking_time");
    }
  },
  actions: {
    login({ commit }, data) {
      return window.axios.post("/api/v2/token/", data).then(res => {
        if (res.data.user.user_role === FOOD_TICKET_MANAGER) {
          commit("setRole", FOOD_TICKET_MANAGER);
        } else if (res.data.user.is_superuser) {
          commit("setRole", "admin");
        } else if (res.data.user.is_admin) {
          commit("setRole", "merchant_admin");
        } else if (res.data.user.is_staff) {
          commit("setRole", "merchant");
        }
        commit("setToken", "JWT " + res.data.access);
        commit("setCheckInTime", res.data.user.check_in_time);
        commit("setCheckOutTime", res.data.user.check_out_time);
        commit("setRefreshToken", res.data.refresh);
        commit("setCompany", res.data.user.company);
        commit("setIsAdmin", res.data.user.is_admin);
        commit("setHasWidget", res.data.user.has_widget);
        commit("setIsSuperuser", res.data.user.is_superuser);
        commit("setIsBookingTime", res.data.user.booking_time);
        let arr = ["rest_zone", "hotel", "guest_house", "sanatorium"];
        if (arr.includes(res.data.user.accommodation_type)) {
          commit("setCompanyType", "simple");
        } else if (
          res.data.user.accommodation_type == "spa" ||
          res.data.user.accommodation_type == "leisure"
        ) {
          commit("setCompanyType", "is_leisure");
        }
        window.axios.interceptors.request.use(
          config => {
            config.headers.Authorization = "JWT " + res.data.access;
            return config;
          },
          error => Promise.reject(error)
        );
        return res;
      });
    },
    logout({ commit }) {
      return this.$http.post("/v1/users/auth").then(res => {
        commit("delToken", res.data.result.token);
        return res;
      });
    }
  },
  getters: {
    getHasWidget() {
      return store.state.has_widget;
    },
    getCheckInTime() {
      return store.state.check_in_time;
    },
    getCheckOutTime() {
      return store.state.check_out_time;
    },
    getIsSuperuser() {
      return store.state.is_superuser;
    },
    getIsAdmin() {
      return store.state.is_admin;
    },
    getIsStaff() {
      return store.state.is_staff;
    },
    getCompany() {
      return store.state.company_id;
    },
    getToken() {
      return store.state.token;
    },
    getUserRole() {
      return store.state.role;
    }
  }
});
