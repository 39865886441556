const url = "/api/v2/booking/";

export const BookingApiUrls = {
  // one booking
  async getBooking(id) {
    return await window.axios.get(url + id + "/");
  },
  async setBooking(id, body) {
    return await window.axios.patch(url + id + "/", body);
  },
  async deleteBooking(id, body) {
    return await window.axios.put(url + "cancel/" + id + "/", body);
  },
  async deletePermanentlyBooking(id) {
    return await window.axios.delete(url + "cancel/" + id + "/");
  },

  // reserve booking
  async reserveBooking(body) {
    return await window.axios.post(url + "reserve/", body);
  },
  // book booking
  async bookBoking(body) {
    return await window.axios.post(url + "book/", body);
  },

  // get tasks
  async getTasks(params) {
    return await window.axios.get(url + "booking-tasks/", {
      params: params
    });
  },
  // set tasks
  async setTask(body, id) {
    return await window.axios.put(url + "booking-tasks/" + id + "/", body);
  },
  // delete task
  async deleteTask(id) {
    return await window.axios.delete(url + "booking-tasks/" + id + "/");
  },
  // удалить все таски
  async deleteAllTasks(body) {
    return await window.axios.post(url + "bulk-remove-tasks/", body);
  },

  // добавить платеж
  async addPayment(body) {
    return await window.axios.post(url + "payments/", body);
  },

  // добавить еду
  async addFood(id, body) {
    return await window.axios.patch(url + id + "/", body);
  },

  // добавить скидку
  async addDiscount(body) {
    return await window.axios.post(url + "booking-discounts/", body);
  },
  // удалить скидку
  async deleteDiscount(id) {
    return await window.axios.delete(url + "booking-discounts/" + id + "/");
  },
  // добавить групповую скидку
  async addGroupDiscount(body) {
    return await window.axios.post(url + "group-discounts/", body);
  },
  // удалить групповую скидку
  async deleteGroupDiscount(id) {
    return await window.axios.delete(url + "group-discounts/" + id + "/");
  },

  // заселить
  async setCheckin(body) {
    return await window.axios.post(url + "check-in/", body);
  },
  // выселить
  async setCheckout(body) {
    return await window.axios.post(url + "check-out/", body);
  },

  // get booking price
  async getPrice(id, body) {
    return await window.axios.post(url + "calculate-price/" + id + "/", body);
  },

  // get notifications
  async getNotifications() {
    return (await window.axios.get(url + "notifications/")).data;
  },

  // get ticket
  async getTicket(id) {
    return (await window.axios.get(url + "booking-ticket/" + id + "/")).data;
  },
  // get group ticket
  async getGroupTicket(id) {
    return (await window.axios.get(url + "group-booking-ticket/" + id + "/"))
      .data;
  },
  // отправить на почту
  async sendEmail(id) {
    return (await window.axios.post(url + "send-email/" + id + "/")).data;
  },
  // отправить групповую бронь на почту
  async sendGroupEmail(id) {
    return (await window.axios.post(url + "group-send-email/" + id + "/")).data;
  },

  // get accommodation bookings
  async getBookings(params) {
    return (
      await window.axios.get(url + "booking-statuses/", {
        params: params
      })
    ).data;
  },

  // book leisure
  async createLeisureBook(body) {
    return (await window.axios.post(url + "leisure/", body)).data;
  },
  // get leisure bookings список броней
  async getLeisureBookings(params) {
    return (
      await window.axios.get(url + "leisure-bookings/", {
        params: params
      })
    ).data;
  },
  // сделать возврат список броней
  async createLeisureReturn(body) {
    return (await window.axios.post(url + "leisure-return/", body)).data;
  },

  // booking-module получить данные от компании: цвета
  async getWidgetInfo(params) {
    return (
      await window.axios.get(url + "booking-module/", {
        params: params
      })
    ).data;
  },
  // save certificate for booking-module
  async setCertificate(formdata, jwt) {
    return (
      await window.axios.patch(
        url + "booking-module/payment-certificate/",
        formdata,
        {
          headers: {
            Authorization: `${jwt}`
          }
        }
      )
    ).data;
  },
  // save colors for booking-module
  async setColors(body, jwt) {
    return (
      await window.axios.patch(url + "booking-module/colors/", body, {
        headers: {
          Authorization: `${jwt}`
        }
      })
    ).data;
  },
  // сгенерировать талоны на одиночную бронь
  async generateSingleTalon(id) {
    return await window.axios.post(url + "talons/single/", {
      id
    });
  },

  ////////////////
  //
  // group booking
  //
  ////////////////

  // get номера групповой брони
  async getGroupBookingRooms(id) {
    return (await window.axios.get(url + "group-bookings/" + id + "/")).data;
  },
  // сделать платеж для групповой брони
  async createGroupPayment(body) {
    return (await window.axios.post(url + "group-payments/", body)).data;
  },
  // удалить групповую бронь
  async deleteGroupBooking(id, body) {
    return await window.axios.put(url + "group-cancel/" + id + "/", body);
  },
  // удалить навсегда групповую бронь
  async deleteGroupPermanentlyBooking(id) {
    return await window.axios.delete(url + "group-cancel/" + id + "/");
  },
  // посчитать стоимость групповой брони
  async getGroupPrice(id, body) {
    return await window.axios.post(
      url + "calculate/group-bookings/" + id + "/",
      body
    );
  },
  // сохранить для всех в групповой брони
  async updateAllBookings(id, body) {
    return await window.axios.patch(
      url + "update-all-bookings/" + id + "/",
      body
    );
  },
  async updateAllBookingsStatus(id, body) {
    return await window.axios.put(
      url + "status/update-all-bookings/" + id + "/",
      body
    );
  },
  // изменить статус существующей брони
  async changeRealBooking(id, body) {
    return await window.axios.patch(url + "status/" + id + "/", body);
  },
  // сгенерировать талоны на групповую бронь
  async generateGroupTalon(id) {
    return await window.axios.post(url + "talons/group/", {
      id
    });
  }
};
