const QR_LINK_KEY = "easybook:qr-link";

/**
 * @description
 * Стоит много плохих проверок на то разлогинен ли юзер и функционал перенаправляет
 * пользователя в /auth/login. Это неправильный подход. Нужно проверять через vue-router hooks
 * Пока добавил такую прослойку для менеджеров ресторана.
 * Зачем я добавил?
 * Потому что менеджер зайдет по ссылке: https://dev.easybook.kz/#/qr?q=123,
 * менеджера перенаправит на /auth/login страницу, а q query param потеряется
 */

export function useQrLinkStorage() {
  function getQrLink() {
    return JSON.parse(localStorage.getItem(QR_LINK_KEY));
  }

  function setQrLink(value) {
    localStorage.setItem(QR_LINK_KEY, JSON.stringify(value));
  }

  function deleteQrLink() {
    localStorage.removeItem(QR_LINK_KEY);
  }

  return {
    getQrLink,
    setQrLink,
    deleteQrLink
  };
}
