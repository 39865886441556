import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import userStore from "./store/auth";

import vuetify from "./plugins/vuetify";
import DatetimePicker from "vuetify-datetime-picker";
import Vuelidate from "vuelidate";
import axiosApi from "axios";
import moment from "moment";

import "./plugins/base";
import "./plugins/vee-validate";
import user from "./store/auth";
import toast from "./plugins/toast";

moment.locale("ru");
moment.tz.setDefault("Etc/GMT-5");

let mobileCheck;
const ua = navigator.userAgent;
let check;
if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua))
  check = "tablet";
else if (
  /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
    ua
  )
)
  check = "mobile";
else check = "desktop";
if (check === "desktop") mobileCheck = false;
else mobileCheck = true;

Vue.prototype.$mobileCheck = mobileCheck;

Vue.use(Vuelidate);
Vue.use(DatetimePicker);

const axios = axiosApi.create({
  baseURL: "/",
  headers: {
    Authorization: localStorage.getItem("token")
  }
});
function createAxiosResponseInterceptor() {
  const interceptor = axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status !== 401 && error.response.status < 499) {
        // тут ищем ошибки
        const errMessage = error.response;
        errMessage.data.error_messages.forEach(e => {
          for (const [key, value] of Object.entries(e)) {
            if (typeof value == "object" && value[0]) e.array_here = true; // проверка на array
          }
        });
        errMessage.data.error_messages.forEach(e => {
          if (e.array_here) {
            for (const [key, value] of Object.entries(e)) {
              toast.error(`${key} : ${value}`);
            }
          } else {
            toast.error(`${e.field} : ${e.message}`);
          }
        });
        return Promise.reject(error);
      } else if (error.response.status !== 401 && error.response.status > 499) {
        toast.error("Извините, произошла ошибка на сервере!");
        return Promise.reject(error);
      }

      axios.interceptors.response.eject(interceptor);

      return axios
        .post("/api/v2/token/refresh/", {
          refresh: localStorage.getItem("refresh_token")
        })
        .then(response => {
          user.commit("setToken", "JWT " + response.data.access);
          error.response.config.headers["Authorization"] =
            "JWT " + response.data.access_token;
          return axios(error.response.config);
        })
        .catch(error => {
          user.commit("delToken");
          router.push("/auth/login");
          return Promise.reject(error);
        })
        .finally(createAxiosResponseInterceptor);
    }
  );
}

// axios.interceptors.request.use(request => {
//   console.log('Starting Request', request);
//   return request;
// });

// axios.interceptors.response.use(response => {
//   console.log('Response:', response);
//   return response;
// });

createAxiosResponseInterceptor();
window.axios = axios;

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  userStore,
  vuetify,
  render: h => h(App)
}).$mount("#app");
