<template>
  <router-view />
</template>

<script>
import { useQrLinkStorage } from "@/views/main/pages/qr";

const { setQrLink } = useQrLinkStorage();

import user from "./store/auth/index";
export default {
  name: "App",
  data() {
    return {};
  },
  watch: {
    "user.getters.getToken"() {
      if (user.getters.getToken === null) {
        this.$router.push(`/auth/login`);
      }
    },
    "$route.fullPath"() {
      if (
        user.getters.getToken === null &&
        this.$route.fullPath !== "/auth/login"
      ) {
        if (this.$route.name === "QR") {
          setQrLink(this.$route.fullPath);
        }
        this.$router.push(`/auth/login`);
      }
    }
  },
  mounted() {
    if (user.getters.getToken === null) {
      this.$router.push(`/auth/login`);
    }
  }
};
</script>
