import { Action } from '@neuronet.io/vido'

export default function WeekendHiglight (options) {
  const weekdays = options.weekdays || [6, 0]
  let className
  let api

  class WeekendHighlightAction extends Action {
    constructor (element, data) {
      super()
      this.highlight(element, data.time.leftGlobal)
    }

    update (element, data) {
      this.highlight(element, data.time.leftGlobal)
    }

    highlight (element, time) {
      const isWeekend = weekdays.includes(api.time.date(time).day())
      const hasClass = element.classList.contains(className)
      if (!hasClass && isWeekend) {
        element.classList.add(className)
      } else if (hasClass && !isWeekend) {
        element.classList.remove(className)
      }
    }
  }

  class DateWeekendHiglight extends Action {
    constructor (element, data) {
      super()
      this.highlight(element, data.date.leftGlobal)
    }

    update (element, data) {
      this.highlight(element, data.date.leftGlobal)
    }

    highlight (element, time) {
      const isWeekend = weekdays.includes(api.time.date(time).day())
      const hasClass = element.classList.contains(className)
      if (!hasClass && isWeekend) {
        element.classList.add(className)
      } else if (hasClass && !isWeekend) {
        element.classList.remove(className)
      }
    }
  }
  return function initialize (vido) {
    api = vido.api
    className = options.className || api.getClass('chart-timeline-grid-row-block') + '--weekend'
    vido.state.update('config.actions.chart-timeline-grid-row-block', actions => {
      actions.push(WeekendHighlightAction)
      return actions
    })
    vido.state.update('config.actions.chart-calendar-date', actions => {
      actions.push(DateWeekendHiglight)
      return actions
    })
  }
}
